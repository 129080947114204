import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { PageLoader } from '../components/PageLoader';
import { PageLayout } from '../components/PageLayout';
import RolePermissionsSearch from './RolePermissionsSearch';
import BackButton from '../atom/BackButton';
import baseUrl from '../tools/baseUrl';

const RoleDisplay = () => {
    const { roleId } = useParams();
    const [roleDetails, setRoleDetails] = useState();

    useEffect(() => {
        // @todo Issue #13 - The token could be passed in but not sure if that is the right way to do things.
        const token = ''; // await getAccessTokenSilently();
        fetch(`${baseUrl}/api/roles/${roleId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.json())
            .then((json) => setRoleDetails(json));
    }, [roleId]);

    if (!roleDetails) {
        return <PageLoader />;
    }

    return (
        <PageLayout>
            <BackButton/>
            <div>
                <ul>
                    <li>
                        <strong>RoleId:</strong> {roleDetails.id}
                    </li>
                    <li>
                        <strong>Name:</strong> {roleDetails.name}
                    </li>
                    <li>
                        <strong>Description:</strong> {roleDetails.description}
                    </li>
                </ul>
            </div>
            <hr />
            <div>
                <RolePermissionsSearch/>
            </div>
        </PageLayout>
    );
};

export default RoleDisplay;
