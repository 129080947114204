import React from 'react';
import NavBar from '../molecules/NavBar';

export const PageLayout = ({ children }) => {
    return (
        <div className="page-layout">
            <NavBar />
            <div className="page-layout__content">{children}</div>
            <h3>Some Footer</h3>
        </div>
    );
};
