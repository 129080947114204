import React from 'react';
import Button from '@mui/material/Button';
import { useAuth0 } from '@auth0/auth0-react';

export default function LoginButton() {
  const {
    loginWithRedirect,
  } = useAuth0();

  return <Button onClick={loginWithRedirect} variant="contained">Log in</Button>
}