import { useEffect, useState } from 'react';
import {
    Link,
    TableCell,
    TableRow,
} from '@mui/material';
import baseUrl from '../tools/baseUrl';
import SearchInput from '../atom/SearchInput';
import CaseSensitiveCheckBox from '../atom/CaseSensitive';
import NoResults from '../atom/NoResults';
import { PageLoader } from '../components/PageLoader';
import TableResults from '../molecules/TableResults';

const RolesSearch = () => {
    const [searchValue, setSearchValue] = useState();
    const [caseSensitive, setCaseSensitive] = useState(false);
    const [apiData, setApiData] = useState();
    const [filteredData, setFilteredData] = useState([]);

    useEffect(() => {
        if (!apiData) {
            // @todo Issue #13 - The token could be passed in but not sure if that is the right way to do things.
            const token = ''; // await getAccessTokenSilently();
            fetch(
                `${baseUrl}/api/roles`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
                .then((res) => res.json())
                .then((json) => {
                    setApiData(json);
                    setFilteredData(json);
                });
        }

        if (!searchValue) {
            setFilteredData(apiData);
        } else {
            const filteredList = apiData.filter((item) => {
                const search = !caseSensitive
                    ? searchValue.toLowerCase()
                    : searchValue;

                const name = !caseSensitive
                    ? item.name.toLowerCase()
                    : item.name;

                return name.includes(search);
            });
            setFilteredData(filteredList);
        }
    }, [apiData, caseSensitive, searchValue]);

    if (!apiData) {
        return (<PageLoader />);
    }

    let results = (<NoResults />);

    if (filteredData.length > 0) {
        results = (
            <TableResults
                columns={['Role', 'Description']}
                rows={filteredData.map((row) => (
                    <TableRow key={row.id}>
                        <TableCell>
                            <Link href={`/roles/${row.id}`}>
                                {row.name}
                            </Link>
                        </TableCell>
                        <TableCell>{row.description}</TableCell>
                    </TableRow>
                ))
                }
            />
        );
    }

    return (
        <div>
            <SearchInput onChange={(value) => setSearchValue(value)} />
            <CaseSensitiveCheckBox onChange={(value) => setCaseSensitive(value)} />
            {results}
        </div>
    );
}

export default RolesSearch;