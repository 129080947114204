import { Card, CardContent, Typography } from "@mui/material";

const NoResults = () => {
    return (
        <Card>
            <CardContent>
                <Typography color="text.secondary" gutterBottom>
                    No results match
                </Typography>
            </CardContent>
        </Card>
    );
}

export default NoResults;