import {
    TableCell,
    TableRow,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import CaseSensitiveCheckBox from '../atom/CaseSensitive';
import NoResults from '../atom/NoResults';
import SearchInput from '../atom/SearchInput';
import { PageLoader } from '../components/PageLoader';
import baseUrl from '../tools/baseUrl';
import TableResults from '../molecules/TableResults';

const RolePermissionsSearch = () => {
    const { roleId } = useParams();
    const [searchValue, setSearchValue] = useState();
    const [caseSensitive, setCaseSensitive] = useState(false);
    const [apiData, setApiData] = useState();
    const [filteredData, setFilteredData] = useState([]);

    let rowCount = 0;

    useEffect(() => {
        // @todo Issue #13 - The token could be passed in but not sure if that is the right way to do things.
        const token = ''; // await getAccessTokenSilently();

        if (!apiData) {
            // @todo this is not the right URL
            fetch(`${baseUrl}/api/role_permissions`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
                .then((res) => res.json())
                .then((json) => {
                    setApiData(json);
                    setFilteredData(json);
                });
        }

        if (!searchValue) {
            setFilteredData(apiData);
        } else {
            const filteredList = apiData.filter((item) => {
                const search = !caseSensitive
                    ? searchValue.toLowerCase()
                    : searchValue;

                const permission_name = !caseSensitive
                    ? item.permission_name.toLowerCase()
                    : item.permission_name;

                return permission_name.includes(search);
            });
            setFilteredData(filteredList);
        }
    }, [apiData, roleId, searchValue, caseSensitive]);

    if (!apiData) {
        return <PageLoader />;
    }

    let filterResults = (
        <NoResults />
    );

    if (filteredData.length > 0) {
        filterResults = (
            <TableResults
                columns={['Name', 'Description', 'Api']}
                rows={filteredData.map((row) => {
                    rowCount++;
                    return (
                        <TableRow key={`permission-${rowCount}`}>
                            <TableCell>{row.permission_name}</TableCell>
                            <TableCell>{row.description}</TableCell>
                            <TableCell>{row.resource_server_name}</TableCell>
                        </TableRow>
                    );
                })}
            />
        );
    }

    return (
        <div>
            <SearchInput onChange={(value) => setSearchValue(value)} />
            <CaseSensitiveCheckBox onChange={(value) => setCaseSensitive(value)} />
            {filterResults}
        </div>
    );
};

export default RolePermissionsSearch;
