import { HomePage } from './templates/HomePage';
import LogoutPage from './templates/LogoutPage';
import {ProtectedRoute} from './components/ProtectedRoute';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { PageLoader } from './components/PageLoader';
import { useAuth0 } from '@auth0/auth0-react';
import RoleDisplay from './organisms/RoleDisplay';

function App() {
    const { isLoading } = useAuth0();

    if (isLoading) {
        return (
            <div className="page-layout">
                <PageLoader />
            </div>
        );
    }

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route
                    path="/roles/:roleId"
                    element={<ProtectedRoute component={RoleDisplay} />}
                />
                <Route path="/logout" element={<LogoutPage />} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
