import { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import baseUrl from '../tools/baseUrl';

export const useMyRoles = () => {
    const { user, getAccessTokenSilently } = useAuth0();
    const [myRoles, setMyRoles] = useState([]);

    useEffect(() => {
        (async () => {
            try {
                if (user) {
                    const token = await getAccessTokenSilently();
                    const response = await fetch(`${baseUrl}/api/my_roles`, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    });
                    setMyRoles(await response.json());
                }
            } catch (e) {
                console.error(e);
            }
        })();
    }, [user, getAccessTokenSilently]);

    const hasRole = myRoles.find((element) => element.id === process.env.REACT_APP_AUTH0_ADMIN_ROLE_ID) ?? false;
    return { myRoles, hasRole };
}