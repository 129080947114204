import React from 'react';
import LoginButton from '../atom/LoginButton';
import LogoutButton from '../atom/LogoutButton';
import { useAuth0 } from '@auth0/auth0-react';

const AuthenticationButton = ({forceAuthenticated}) => {
  let { isAuthenticated } = useAuth0();
  
  if (forceAuthenticated) {
    isAuthenticated = forceAuthenticated;
  }
  return isAuthenticated ? <LogoutButton /> : <LoginButton />;
};

export default AuthenticationButton;