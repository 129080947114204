import { useAuth0 } from '@auth0/auth0-react';
import { Alert } from '@mui/material';
import React from 'react';
import { PageLayout } from '../components/PageLayout';
import { useMyRoles } from '../hooks/useMyRoles';
import RolesSearch from '../organisms/RolesSearch';

export const HomePage = () => {
    const { isAuthenticated } = useAuth0();
    const { hasRole } = useMyRoles();

    return (
        <PageLayout>
            <div>
                <h1>HomePage</h1>
                {isAuthenticated && hasRole ? (
                    <RolesSearch />
                ) : (
                    <Alert severity="error">
                        You do not have the right role
                    </Alert>
                )}
            </div>
        </PageLayout>
    );
};
