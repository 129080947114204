import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { Auth0ProviderWithConfig } from './components/Auth0ProviderWithConfig';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <Auth0ProviderWithConfig>
            <App />
        </Auth0ProviderWithConfig>
    </React.StrictMode>
);

// @todo Issue #14 - Fix authentication session - https://developer.auth0.com/resources/code-samples/spa/react-javascript/basic-authentication-with-react-router-6

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
