import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material"
import PropTypes from 'prop-types';

const TableResults = ({ columns, rows }) => {

    const columnHeadings = [];
    if (columns && columns.length > 0) {
        columns.forEach((row) => columnHeadings.push(<TableCell>{row}</TableCell>))
    }

    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        {columnHeadings}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

TableResults.propTypes = {
    columns: PropTypes.array,
    rows: PropTypes.array
}

export default TableResults;