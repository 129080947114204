import React from 'react';
import AuthNav from './AuthNav';

const NavBar = () => {
  return (
    <div id="navBar" className="nav-container mb-3">
      <nav className="navbar navbar-expand-md navbar-light bg-light">
        <div className="container">
          <AuthNav />
        </div>
      </nav>
    </div>
  );
};

export default NavBar;