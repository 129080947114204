import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import PropTypes from 'prop-types';

const CaseSensitiveCheckBox = ({ onChange }) => {
    const handleChange = event => {
        const checked = event.target.checked;
        onChange(checked);
    };

    return (
        <FormGroup>
            <FormControlLabel
                label="Case Sensitive"
                control={
                    <Checkbox
                        name="caseSensitive"
                        size="small"
                        onChange={handleChange}
                    />
                }
            />
        </FormGroup>
    );
}

CaseSensitiveCheckBox.propTypes = {
    onChange: PropTypes.func
};

export default CaseSensitiveCheckBox;