import { Input } from "@mui/material";
import PropTypes from 'prop-types';

const SearchInput = ({ onChange }) => {
    const handleChange = event => {
        const text = event.target.value;
        onChange(text);
    };

    return (
        <Input
            name="search"
            icon="search"
            placeholder="Search..."
            onChange={handleChange}
        />
    );
}

SearchInput.propTypes = {
    onChange: PropTypes.func
};

export default SearchInput;