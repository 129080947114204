import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';

const LogoutPage = () => {
    const { logout } = useAuth0();

    useEffect(() => {
        (async () => {
            logout({ returnTo: window.location.origin });
        })();
    });
}

export default LogoutPage